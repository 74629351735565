import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const { token } = this.localStorageService;
		let authReq = req;

		if (token) {
			authReq = req.clone({
				headers: req.headers.set('Authorization', token),
			});
		}

		return next.handle(authReq).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401) {
						// Перенаправление на главную страницу в случае ошибки 401
						localStorage.clear();
						this.router.navigate(['/']);
					} else {
						// Сохраняем существующую логику обработки ошибок
						if (!error.ok) {
							console.error('Запрос не прошел');
						}
					}
				}
				return throwError(error);
			})
		);
	}
}
