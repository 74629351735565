import { inject } from '@angular/core';
import { RedirectCommand, ResolveFn, Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage.service';
import { User } from '../interfaces/enums';
import { IUserStorageData } from '../interfaces/users';

export const ProfileResolver: ResolveFn<
	IUserStorageData
> = (): IUserStorageData | RedirectCommand => {
	const localStorageService = inject(LocalStorageService);
	const router = inject(Router);
	const data = localStorageService.getItemLocalStorage<IUserStorageData>(
		User.USER_DATA
	);

	if (!data) {
		return new RedirectCommand(router.parseUrl(''));
	}

	return data;

};
