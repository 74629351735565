import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Nora } from 'primeng/themes/nora';
import { definePreset } from 'primeng/themes';
import { take } from 'rxjs';
import { LocalStorageService } from './core/services/localStorage.service';
import { CoordinateService } from './core/services/coordinates/coordinate.service';
import { TokenService } from './core/services/api/user/token.service';
import { User } from './core/interfaces/enums';
import { IUserStorageData } from './core/interfaces/users';
import { SessionUser } from './utils/session-user.abstract';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(
		private coordinateService: CoordinateService,
		private config: PrimeNGConfig,
		private tokenService: TokenService,
		private localStorageService: LocalStorageService,
	) {
		coordinateService.init();
		const myPreset = definePreset(Nora, {
			semantic: {
				primary: {
					50: '#3e7efd',
					100: '#3e7efd',
					200: '#3e7efd',
					300: '#3e7efd',
					400: '#3e7efd',
					500: '#3e7efd',
					600: '#3e7efd',
					700: '#3e7efd',
					800: '#3e7efd',
					900: '#3e7efd',
					950: '#3e7efd',
				},
			},
		});
		this.config.theme.set({
			preset: myPreset,
			options: {
				prefix: 'p',
				darkModeSelector: '',
				cssLayer: false,
			},
		});
		const user = this.localStorageService.getItemLocalStorage<IUserStorageData | undefined>(User.USER_DATA);
		if (user?.authToken) {
			this.tokenService.refresh().pipe(take(1)).subscribe();
			SessionUser.changeIsAuth = true;
		} else {
			SessionUser.changeIsAuth = false;
		}
	}
}
