import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../directives/directive.module';
import { SharedModule } from '../shared.module';
import { EventFormComponent } from './event/event';
import { PipeModule } from '../pipes/pipes.module';

@NgModule({
	imports: [
		ReactiveFormsModule,
		DirectiveModule,
		CommonModule,
		SharedModule,
		FormsModule,
		PipeModule,
	],
	declarations: [EventFormComponent],
	exports: [],
})
export class ModalModule {}
