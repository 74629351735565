import { Injectable } from '@angular/core';
import {
	Router,
} from '@angular/router';
import { User } from '../interfaces/enums';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private router: Router) {}

	canActivate(
	): boolean {
		if (localStorage.getItem(User.USER_DATA)) {
			return true;
		}
		// Если пользователь не авторизован, перенаправляем его на страницу home
		this.router.navigate(['']);
		return false;
	}
}
