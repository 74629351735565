<div class="bottom-nav">
	<div class="nav-item" (click)="navigateToHome()">
		<i class="pi pi-home" style="font-size: 1.5rem;"></i>
		<span>Главная</span>
	</div>
	<div class="nav-item">
		<i class="pi pi-plus-circle" style="font-size: 1.5rem;"></i>
		<span>Добавить</span>
	</div>
	@if (isAuth) {
		<div class="nav-item" (click)="navigateToProfile()">
			<i class="pi pi-user" style="font-size: 1.5rem;"></i>
			<span >Профиль</span>
		</div>
	} @else {
		<div class="nav-item" (click)="showDialog()">
			<i class="pi pi-sign-in" style="font-size: 1.5rem;"></i>
			<span>Войти</span>
		</div>
	}
</div>

<p-dialog header="Форма входа" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
	<app-sign-in></app-sign-in>
</p-dialog>
