import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './shared/modal/error-page/error-page.component';
import { AuthGuard } from './core/guard/AuthGuard';
import { ProfileResolver } from './core/resolvers/profile.resolver';

const routes: Routes = [
	{
		path: 'profile',
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.ProfileModule
			),
		canActivate: [AuthGuard],
		resolve: { userData: ProfileResolver },
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'event',
		loadChildren: () =>
			import('./pages/event/event.module').then((m) => m.EventModule),
	},
	{
		path: '**',
		pathMatch: 'full',
		component: ErrorPageComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
